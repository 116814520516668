import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { currencyList } from "../data/merchantList";
import * as DepositService from "../services/DepositService";
import { getDeviceIP } from "./common/getIP";
import moment from "moment";
import { useSelector } from "react-redux";

const DepositWithCard = ({ show, onHide }) => {

  const paymentRedirectUrl = useSelector((state) => state.config.payment_redirect_url);
  const webRedirectUrl = useSelector((state) => state.config.web_redirect_url);
  const [name, setName] = useState("");
  const cardInput = useRef(null);
  const [getTokenLoading, setGetTokenLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showCreditCard, setShowCreditCard] = useState(true);
  const [errForm, setErrForm] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const getIpAsyncCall = async () => {
    const ipAddress = await getDeviceIP()
    setIpAddress(ipAddress);
  }

  useEffect(() => {
    getIpAsyncCall()
  }, [])

  const merchantDetails = [
    {
      label: "Merchant Id",
      key: "merchantId",
    },
    {
      label: "Merchant Ref",
      key: "merchantRef",
    },
    {
      label: "Merchant User Id",
      key: "merchantUserId",
    },
    {
      label: "Merchant User Email",
      key: "merchantUserEmail",
    },
    {
      label: "Merchant User Mobile",
      key: "merchantUserMobile",
    },
    {
      label:"Merchant Host Url",
      key:"merchantHostUrl",
    },
    {
      label: "Amount",
      key: "amount",
    },
    {
      label: "Network",
      key: "network",
    },
    {
      label: "ApiKey",
      key: "apiKey",
    },
    {
      label: "Success URL",
      key: "redirectUrlSuccess",
    },
    {
      label: "Failure URL",
      key: "redirectUrlFail",
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Billing Address 1",
      key: "billingAddress1",
    },
    {
      label: "Billing Post Code",
      key: "billingPostcode",
    },
    {
      label: "Billing City",
      key: "billingCity",
    },
    {
      label: "Billing State",
      key: "billingState",
    },
    {
      label: "Billing Country",
      key: "billingCountry",
    }
  ];
  const [merchantData, setMerchantData] = useState({
    merchantId: "1",
    merchantRef: generateRandomString(10),
    merchantUserId: "1mUsrId",
    merchantUserEmail: "test@merchant.com",
    merchantHostUrl: "",
    amount: "",
    currency: "USD", //fiatCurrency
    "description": "Crypto purchase test",
    network: "testnet",
    apiKey: "4c70dbb3-4260-4f58-829f-61003b669c48",
    redirectUrlSuccess:
      "https://www.google.com/",
    redirectUrlFail:
      "https://twitter.com/",
    firstName: "",
		lastName: "",
		billingAddress1: "",
		billingCity: "",
		billingPostcode: "",
		billingState: "",
		billingCountry: "",
    pan: "",
    expirydate: "",
    securitycode: "",
  });

  const [redirectedModal, setRedirectedModal] = useState(false);

  const iframeStyle = {
    height: "585px",
    width: "100%",
  };

  const getCardNumentMasked = (val) => {
    var newval = "";
    val = val.replace(/\s/g, "");
    for (var i = 0; i < val.length; i++) {
      if (i % 4 === 0 && i > 0) newval = newval.concat("  ");
      newval = newval.concat(val[i]);
    }
    return newval;
  };

  const getExpiryDateMasked = (val) => {
    var newval = "";
    val = val.replace(/\s/g, "");
    for (var i = 0; i < val.length; i++) {
      if (i % 2 === 0 && i > 0 && i < 3) newval = newval.concat(" / ");
      newval = newval.concat(val[i]);
    }
    return newval;
  };

  const submitData = () => {
    let date =
      merchantData.expirydate.substring(0, 2) +
      "/" +
      merchantData.expirydate.substring(2, 6);
    let datenone = moment().valueOf()
    const dataObj = {
      ...merchantData,
      ipAddress,
      brand: "exampledatabrand",
      useBrand: "exampledatausebrand",
      pan: merchantData.pan,
      expirydate: date,
      securitycode: merchantData.securitycode,
      amount:merchantData.amount,
      merchantTransactionId: "merTran-" + datenone,
      "browser_timezone": new Date().toString().match(/([-+][0-9]+)\s/)[1],
      "browser_color_depth": window.screen.colorDepth,
      "browser_language": navigator.language,
      "browser_screen_height": window.innerHeight,
      "browser_screen_width": window.innerWidth,
      "os": navigator.platform,
      "browserUserAgent": navigator.userAgent, 
    };
    setGetTokenLoading(true);
    DepositService.postCardData(dataObj, paymentRedirectUrl)
      .then((response) => {
        setGetTokenLoading(false);
        if (response.data) {
          setError(null);
          setRedirectUrl(response.data.transactionURL);
          setRedirectedModal(true)
          return;
        } else {
          setError(response.data.Error);
          // setRedirectedModal(true);
          onHide();
          window.open(
            response.data.transactionURL,
            "myWindow",
            "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=800"
          )
          // window.location.href = response.data.transactionURL;
        }
      })
      .catch((e) => {
        setGetTokenLoading(false);
        if (e && e.response && e.response.data && e.response.data.transactionURL) {
          setErrorMessage(null);
          setRedirectUrl(e.response.data.transactionURL);
          // setRedirectedModal(true);
          onHide();
          window.open(
            e.response.data.transactionURL,
            "myWindow",
            "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=800"
          )
        } else {
          console.log("else")
          setErrorMessage(null);
          setRedirectUrl(webRedirectUrl+"/creditCard/depositFailed");
          // setRedirectedModal(true);
          onHide();
          window.open(
            webRedirectUrl+"/creditCard/depositFailed",
            "myWindow",
            "toolbar=no,scrollbars=no,resizable=yes,top=80,left=250,width=939,height=800"
          )
        }
        if (e) {
          setErrorMessage("Server Error");
          return;
        }
      });
  };
  const onClose = () => {
    setRedirectedModal(false);
    onHide();
  };

  const handleChange = (evt) => {
    setMerchantData({
      ...merchantData,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleConfirm = () => {
    if (merchantData.amount === "" || 0) {
      return setErrForm("Enter valid amount");
    } else {
      setErrForm("");
    }

    setShowCreditCard(false);
  };
  return showCreditCard ? (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton style={{ backgroundColor: "#1d1d1d" }}>
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ color: "#fff" }}
        >
          Make a Deposit Card
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px", backgroundColor: "#000" }}>
        <div className="backDiv"></div>
        <div className="mainDiv">
          <div>
            <span>CRYPTON</span>
            <br />
            <span>PAYMENTS</span>
          </div>
          <div className="card">
            <h5 className="card-header">Merchant Details</h5>
            <div className="card-body">
              <div style={{ marginTop: 20, lineHeight: "25px" }}>
                {merchantDetails &&
                  merchantDetails.map(({ label, key }) => {
                    return (
                      <div className="form-group row" key={key}>
                        <label htmlFor={key} className="col-3 col-form-label">
                          {label}:
                        </label>
                        <div className="col-9">
                          <input
                            className="form-control"
                            name={key}
                            data-e2e={key}
                            id={key}
                            defaultValue={merchantData[key]}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    );
                  })}
                <div className="form-group row">
                  <label htmlFor="currency" className="col-3 col-form-label">
                    Currency
                  </label>
                  <div className="col-9">
                    <select
                      value={merchantData["currency"]}
                      className="form-control selectMerchant"
                      id="currency"
                      name="currency"
                      data-e2e="currency"
                      onChange={handleChange}
                    >
                      {currencyList &&
                        currencyList.length &&
                        currencyList.map(({ value }) => {
                          return (
                            <option value={value} key={value}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errForm !== "" && <div className="error"> {errForm} </div>}
          <Button
          data-e2e="confirm-btn"
            className="depositBtn"
            size="lg"
            block
            onClick={() => {
              handleConfirm();
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <React.Fragment>
      <Modal show={show} onHide={onHide} className="deposit-card-modal-content">
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#1d1d1d", borderBottom: "unset" }}
        >
          <Modal.Title style={{ color: "#fff" }}>
          <i
            className="fa-solid fa-arrow-left"
            style={{ marginRight: "10px", cursor:'pointer' }}
            onClick={() => {
              setShowCreditCard(true)
            }}
          />
            <span>Make a Deposit With Card</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="deposit-card-modal-body">
          <div className="creditCardContainer text_color_white">
            <div className="creditCardPaymentFiled">
              <div className="creditCardInputWrap">
                <div className="title">Card Number</div>
                <div className="inputWrap text_color_white">
                  <input
                  data-e2e="card-number"
                    type="text"
                    name="pan"
                    id="pan"
                    value={getCardNumentMasked(merchantData.pan)}
                    onChange={(e) => {
                      setMerchantData({
                        ...merchantData,
                        pan: e.target.value
                          .replace(/\s/g, "")
                          .replace(/\D[^.]/g, "")
                          .substring(0, 16),
                      });
                      setError("");
                    }}
                    ref={cardInput}
                    pattern="[0-9]+"
                    className="inputField text_color_white"
                    placeholder="••••  ••••  ••••  ••••"
                  />
                </div>
              </div>
              <div className="creditCardInputWrap">
                <div className="title">Name on Card</div>
                <div className="inputWrap">
                  <input
                  data-e2e="card-name"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setError("");
                    }}
                    className="inputField text_color_white"
                    placeholder="Enter card holders name"
                  />
                </div>
              </div>
              <div className="creditCardInputContainer">
                <div className="creditCardInputWrap creditCardInputExpiryWrap">
                  <div className="title">Expiry date</div>
                  <div className="inputWrap">
                    <input
                      type="text"
                      data-e2e="expiry-date"
                      name="expirydate"
                      id="expirydate"
                      value={getExpiryDateMasked(merchantData.expirydate)}
                      onChange={(e) => {
                        setMerchantData({
                          ...merchantData,
                          expirydate: e.target.value
                            .replace(/\s/g, "")
                            .replace(/[^0-9.]/g, "")
                            .substring(0, 6),
                        });
                        setError("");
                      }}
                      className="inputField text_color_white"
                      placeholder="MM / YYYY"
                    />
                  </div>
                </div>
                <div className="reditCardInputWrap creditCardInputCVVWrap ml-10">
                  <div className="title">CVV/CVC</div>
                  <div className="inputWrap">
                    <input
                      type="text"
                      data-e2e="security-code"
                      name="securitycode"
                      id="securitycode"
                      value={merchantData.securitycode}
                      onChange={(e) => {
                        setMerchantData({
                          ...merchantData,
                          securitycode: e.target.value
                            .replace(/\s/g, "")
                            .replace(/[^0-9.]/g, "")
                            .substring(0, 3),
                        });
                        setError("");
                      }}
                      className="inputField text_color_white"
                      placeholder="123"
                    />
                  </div>
                </div>
              </div>
            </div>
            {error !== "" && <div className="errorMsg">{error}</div>}
            <div
              className={`ANC_Continue_btn ${
                merchantData.securitycode &&
                merchantData.securitycode.length === 3 &&
                !isNaN(merchantData.securitycode) &&
                merchantData.pan &&
                merchantData.pan.length === 16 &&
                !isNaN(merchantData.pan) &&
                merchantData.expirydate &&
                merchantData.expirydate.length === 6 &&
                !isNaN(merchantData.expirydate) &&
                name &&
                name.length > 2 &&
                !getTokenLoading
                  ? ""
                  : "ANC_Continue_btn_disabled"
              }`}
              onClick={() => {
                if (
                  merchantData.securitycode &&
                  merchantData.securitycode.length === 3 &&
                  !isNaN(merchantData.securitycode) &&
                  merchantData.pan &&
                  merchantData.pan.length === 16 &&
                  !isNaN(merchantData.pan) &&
                  merchantData.expirydate &&
                  merchantData.expirydate.length === 6 &&
                  !isNaN(merchantData.expirydate) &&
                  name &&
                  name.length > 2 &&
                  !getTokenLoading
                ) {
                  submitData();
                } else if (
                  !(
                    merchantData.pan &&
                    merchantData.pan.length === 16 &&
                    !isNaN(merchantData.pan)
                  )
                ) {
                  setError("Invalid Credit Card Number");
                } else if (
                  !(
                    merchantData.expirydate &&
                    merchantData.expirydate.length === 6 &&
                    !isNaN(merchantData.expirydate)
                  )
                ) {
                  setError("Invalid Expiry Date");
                } else if (
                  !(
                    merchantData.securitycode &&
                    merchantData.securitycode.length === 3 &&
                    !isNaN(merchantData.securitycode)
                  )
                ) {
                  setError("Invalid CVC / CVV");
                } else {
                  setError("Invalid Card Details!");
                }
              }}
              data-e2e={`${redirectUrl}`}
              data-e2e-btn="deposit-btn"
            >
              Deposit
            </div>
            {redirectUrl && <div className="redirect_url_div">
            <div >Redirect URL:</div>
            <div className="redirect_url_style" data-e2e="redirect-url" data-e2e-url={redirectUrl}>
              {redirectUrl}
            </div>
          </div>}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={redirectedModal} onHide={onClose}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#1d1d1d", borderBottom: "unset" }}
        ></Modal.Header>
        <Modal.Body style={{ padding: "0" }}>
          <iframe
          data-e2e="iframe2"
          title="iframe2"
          className="parent-iframe"
            src={redirectUrl}
            width="200"
            height="200"
            style={iframeStyle}
          />
        </Modal.Body>
        <div>{errorMessage}</div>
      </Modal>
    </React.Fragment>
  );
};

export default DepositWithCard;
